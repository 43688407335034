import React from "react"
import { FaCode, FaInfoCircle, FaServer } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Aplicaciones Empresariales",
    text: `Desarrollo de sistemas de misión crítica apoyado en herramientas LowCode. Nómina, Facturación Electrónica, ERP.`,
    url: "https://facturacion.encaladadiaz.com/",
  },
  {
    id: 2,
    icon: <FaServer className="service-icon" />,
    title: "Administración de Sistemas",
    text: `Configuración y gestión de servidores On Premise o Cloud. VPN, firewall, virtualización, bases de datos.`,
    url: "https://gitlab.com/karloskode/infraestructura-encdia",
  },
  {
    id: 3,
    icon: <FaInfoCircle className="service-icon" />,
    title: "Asesoría Tecnológica",
    text: `Planificación, definición, desarrollo y despliegue de proyectos. Direccionamiento para gestionar tu infraestructura.`,
    url: "#"
  },
]
